
import React from 'react';

export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'dashboard'
    },
    {
      name: 'Brands',
      url: '/brands',
      icon: 'brands',
      attributes: {},
      children: [
        {
          name: '- Brands list',
          url: '/brands/brands',
        },
        {
          name: '- Add Brand',
          url: '/brands/new',
        }
      ]
    },
    {
      name: 'Brand Accounts',
      icon: 'brands-account',
      attributes: {},
      children: [
        {
          name: '- See list',
          url: '/brand-accounts/see',
        },
        // {
        //   name: '- Edit list',
        //   url: '/create-brand-account/edit',
        // },

        {
          name: '- Create',
          url: '/brand-accounts/create',
        }
      ]
    },
    {
      name: 'Offers',
      url: '/offers',
      icon: 'offers',
      children: [
       {
          name: '- Coupons CLO',
          url: '/coupons/coupons',
          attributes: {}
        },
        {
          name: '- Cashbacks CLC',
          url: '/cashbacks/cashbacks',
          attributes: {}
        },
        {
          name: '- Contracts CLC',
          url: '/contracts/contracts',
          attributes: {}
        }
      ],
    },
    {
      name: 'Customers',
      url: '/customers',
      icon: 'customers',
      attributes: {},
      children: [
        {
          name: '- Customers list',
          url: '/customers/customers',
        }
      ]
    },
    {
      name: 'Clicks',
      url: '/clicks/',
      icon: 'clicks',
      attributes: {}
    },
    {
      name: 'Payments',
      url: '/payments/',
      icon: 'icon-layers',
      attributes: {},
    },
    {
      name: 'Invoices',
      url: '/invoices/',
      icon: 'debit',
      attributes: {},
    },
    {
      name: 'Payouts',
      url: '/payouts/',
      icon: 'debit',
      attributes: {},
      children: [
        {
          name: '- Create Payouts',
          url: '/payouts/create/',
        },
        {
          name: '- List Payouts',
          url: '/payouts/list',
        }
      ]
    },
    {
      name: 'Transactions',
      url: '/transactions',
      icon: 'transactions',
      attributes: {},
      children: [
        {
          name: '- Transactions list',
          url: '/transactions/transactions',
        },
        {
          name: '- Add Transaction',
          url: '/transactions/new',
        }
      ]
    },
    {
      name: 'Contract Types',
      url: '/contract-types',
      icon: 'contract-types',
      attributes: {},
      children: [
        {
          name: '- Contract Types list',
          url: '/contract-types/contract-types',
        },
        {
          name: '- Add Contract Type',
          url: '/contract-types/new',
        }
      ]
    },
    {
      name: 'Reviews',
      url: '/reviews/reviews',
      icon: 'reviews',
      attributes: {}
    },
    {
      name: 'CMS',
      icon: 'cms',
      attributes: { disabled: true },
      children: [
        {
          name: '- User Portal',
          url: 'https://meinevolksbank.meinplus.de/bfd0hubp0rtl/login',
          attributes: { target: '_blank' , className: 'cms_link' },
        },
        {
          name: '- Brand Portal',
          url: 'https://brandportal.benfido.io/bfd0hubp0rtl/login',
          attributes: { target: '_blank',  className: 'cms_link' },
        }
      ]
    },
    {
      name: 'Banks',
      url: '/banks',
      icon: 'banks',
      attributes: {},
      children: [
        {
          name: '- Banks list',
          url: '/banks/banks',
        },
        // {
        //   name: '- Add Bank',
        //   url: '/banks/new',
        // }
      ]
    },
    {
      name: 'Settings',
      url: '/not-found',
      icon: 'settings',
      attributes: { disabled: true },
      children: [
        {
          name: '- Countries',
          url: '/countries',
          attributes: {},
        },
        {
          name: '- Categories',
          url: '/categories',
          attributes: {}
        },
        {
          name: '- Languages',
          url: '#',
          attributes: { disabled: true },
        },
        {
          name: '- Admin users',
          url: '#',
          attributes: { disabled: true },
        },
      ]
    }
  ],
};
