// Dependencies
import axios from 'axios';
import { API_URL, X_API_KEY } from '../conf.js';
import { getCookie } from '../shared/utils/cookies';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';

// Action Types
import {
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_ERROR,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  ADD_CUSTOMER_REQUEST,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  GET_CUSTOMER_TRANSACTIONS_REQUEST,
  GET_CUSTOMER_TRANSACTIONS_SUCCESS,
  GET_CUSTOMER_TRANSACTIONS_ERROR,
  GET_CUSTOMER_PAYMENTS_REQUEST,
  GET_CUSTOMER_PAYMENTS_SUCCESS,
  GET_CUSTOMER_PAYMENTS_ERROR,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_ERROR
} from './actionTypes';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';

const getCustomer = (params) => dispatch => {
  dispatch(request(GET_CUSTOMER_REQUEST));
  var id = '';
  var bank_id = '';
  if (typeof (params) !== 'undefined') {
    id = params.id;
    bank_id = params.bank_id;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/customers/' + id + '?bank_id=' + bank_id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_CUSTOMER_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_CUSTOMER_ERROR));
    });
};

const getCustomers = (params) => dispatch => {
  dispatch(request(GET_CUSTOMERS_REQUEST));
  var perPage = 10;
  var page = 1;
  console.log('GET_CUSTOMERS');
  console.log(params);
  var url = API_URL + '/customers?';
  if (typeof (params) != 'undefined') {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    console.log(queryString);
    url += queryString;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_CUSTOMERS_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_CUSTOMERS_ERROR));
    });
};

const getCustomerTransactions = (params) => dispatch => {
  dispatch(request(GET_CUSTOMER_TRANSACTIONS_REQUEST));
  var perPage = 10;
  var page = 1;
  console.log('GET_CUSTOMER TRANSACTIONS');
  console.log(params);
  var url = API_URL + '/transactions?';
  if (typeof (params) != 'undefined') {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    console.log(queryString);
    url += queryString;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_CUSTOMER_TRANSACTIONS_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_CUSTOMER_TRANSACTIONS_ERROR));
    });
};

const getCustomerPayments = (params) => dispatch => {
  dispatch(request(GET_CUSTOMER_PAYMENTS_REQUEST));
  var perPage = 10;
  var page = 1;
  console.log('GET_CUSTOMER PAYMENTS');
  console.log(params);
  var url = API_URL + '/payments?';
  if (typeof (params) != 'undefined') {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    console.log(queryString);
    url += queryString;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_CUSTOMER_PAYMENTS_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_CUSTOMER_PAYMENTS_ERROR));
    });
};

const addCustomer = (values, bank_id, gender = null) => dispatch => {
  dispatch(request(ADD_CUSTOMER_REQUEST));
  console.log('ADD_CUSTOMER');
  console.log(values);
  values.profile = {
    country_id: values?.country_code || 1,
    country: {id: values?.country_code || 1},
    gender: gender
  }

  // Axios Data
  const axiosData = {
    method: 'POST',
    url: API_URL + '/customers?bank_id='+bank_id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: values
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(ADD_CUSTOMER_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err);
      dispatch(error(ADD_CUSTOMER_ERROR, err.response));
    });
};

const updateCustomer = (id, values, bank_id, gender) => dispatch => {
  dispatch(request(UPDATE_CUSTOMER_REQUEST));
  console.log('UPDATE_CUSTOMER');
  console.log(id)
  console.log(values);
  console.log(gender);
  

  let obj = {
    email: values.email,
    password: values.password,
    locale	: values.locale,
    active	: values.active,
    reference	: values.reference,
    profile : {
      gender: gender,
      salutaion: values.profile.salutaion,
      first_name	: values.profile.first_name,
      last_name: values.profile.last_name,
      birthday	: values.profile.birthday,
      country_id	: values.profile.country_id,
    },
    id: id
  }

  console.log(obj);

  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/customers/' + id + '?bank_id='+bank_id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: obj
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(UPDATE_CUSTOMER_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(UPDATE_CUSTOMER_ERROR, err.response));
    });
};

const getCustomerInfo = (params) => {
  var id = '';
  var bank_id = '';
  if (typeof (params) !== 'undefined') {
    id = params.id;
    bank_id = params.bank_id;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/customers/' + id + '?bank_id=' + bank_id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => (response.data))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      return err
    });
};




const updateCustomerIban = (id, values, bank_id)  => {
  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/customers/' + id + '?bank_id='+bank_id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: values
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => response.data['form'])
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      return err
    });
};



const deleteCustomer = (bank_id, id) => dispatch => {
  dispatch(request(DELETE_CUSTOMER_REQUEST));
  // Axios Data
  const axiosData = {
    method: 'DELETE',
    url: API_URL + '/customers/' + id + `?bank_id=${bank_id}`,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
      .then(response => dispatch(received(DELETE_CUSTOMER_SUCCESS, response.data)))
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('AXIOS ERROR:', err.response);
        dispatch(error(DELETE_CUSTOMER_ERROR, err.response));
      });
};

const resetCustomer = () => dispatch => {
  dispatch(received(ADD_CUSTOMER_SUCCESS, []))
};

export { getCustomerInfo, updateCustomerIban, getCustomer,deleteCustomer, getCustomers, addCustomer, updateCustomer, resetCustomer, getCustomerTransactions, getCustomerPayments };
